// ============================================================
// ATTENTION & COMMON RULE!!
// 関数を実装のみ（処理の実行は下部で実行する）
// 関数名には振る舞いを表す英語プラスFuncを付ける
// ============================================================

// レスポンシブ判定 & デバイス判定関数（PC,SP共通処理）
//-------------------------------------------------------------
// ウォーターマーク、レスポンシブ判定 & デバイス判定関数（PC,SP共通処理）
//-------------------------------------------------------------
function checkDeviceAndWidthFunc(){
  var pgwBrowser = $.pgwBrowser();
  var jsBr = pgwBrowser.browser.group;
  var jsBrVer = pgwBrowser.browser.group + pgwBrowser.browser.majorVersion;
  var jsOS = pgwBrowser.os.group;
  var jsOsVer = pgwBrowser.os.group + pgwBrowser.os.majorVersion;
  //console.info('横幅:' + pgwBrowser.viewport.width + 'px / 縦幅:' + pgwBrowser.viewport.height + 'px');
  //console.info(pgwBrowser.userAgent);
  $('html').addClass(jsBr + ' ' + jsBrVer + ' ' + jsOS + ' ' + jsOsVer);

}

function mq(device) {
  if (window.matchMedia('(max-width:768px)').matches && device == "sp") {
    return true;
  } else if (window.matchMedia('(min-width:769px)').matches && device == "pc") {
    return true;
  }
  return false;
}


//ページ内スクロール関数（PC,SP共通処理）
//-------------------------------------------------------------
function smoothScrollMoveFunc($goToClassName){

  var $goToClassName = $($goToClassName);
  $goToClassName.click(function(){
    var speed = 500;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "/index.php#category" ||href == "" ? 'html' : href);
    var position;
    var header  = $(".header");
    var viewTop = $(window).scrollTop();
    

    //上スクロールと下スクロールでヘッダーの高さが変化する時はこちらの記述を仕様
    //変化しない場合は、mq('sp')内のif文が無いバージョンでOK
    if (mq('sp')) {
      if(viewTop < target.offset().top ){
        position = target.offset().top - 53;
      }else{
        position = target.offset().top - 126;
      }
    } else {
      position = target.offset().top - 100;
    }
    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });
}

//ページ内スクロール関数（別ページから遷移するタイプ）
//-------------------------------------------------------------
function smoothScrollOtherPage () {
  var flug = true;
  $(document).ready(function(){
    //URLのハッシュ値を取得
    var urlHash = location.hash;
    console.log(urlHash);

    if(flug){
      //ハッシュ値があればページ内スクロール
      if(urlHash) {
        //スクロールを0に戻す
        $('body,html').stop().scrollTop(0);
        setTimeout(function () {
          //ロード時の処理を待ち、時間差でスクロール実行
          scrollToAnker(urlHash) ;
        }, 1);
      }

      var headerHeight = $('.header').outerHeight();
      var viewTop = $(window).scrollTop();
      if(mq("sp")){
        var target = $(urlHash);
        if(viewTop < target.offset().top ){
          headerHeight = 43;
        }else{
          headerHeight = 116;
        }
      }

      //通常のクリック時
  //    $('a[href*="#"]').click(function() {
  //      //ページ内リンク先を取得
  //      var href= $(this).attr("href");
  //      //リンク先が#か空だったらhtmlに
  //      var hash = href == "#" || href == "" ? 'html' : href;
  //      //スクロール実行
  //      scrollToAnker(urlHash);
  //      //リンク無効化
  //      return false;
  //    });

      // 関数：スムーススクロール
      // 指定したアンカー(#ID)へアニメーションでスクロール
      function scrollToAnker(hash) {
        var target = $(hash);
        var position = target.offset().top - headerHeight - 10;
        $('body,html').stop().animate({scrollTop:position}, 500);
      }
      flug = false;
    }
  });
}


//ページトップ
//-------------------------------------------------------------
function goToPageTopFunc($pageTopId){

  var $pageTopId = $($pageTopId);
  var scrollTop = 0;

  if($('.preparing').length){
    var fadeInPageTop = 100;
  } else {
    var fadeInPageTop = 600;
  }

  $(window).on('scroll', function(){
    scrollTop = $(window).scrollTop();

    if(scrollTop > fadeInPageTop){
      $pageTopId.addClass('show');
    } else {
      $pageTopId.removeClass('show');
    }
  });

  $pageTopId.click(function() {
    $("html, body").animate({scrollTop:0}, 500, "swing");
    return false;
  });

}


//ヘッダーの背景色、固定、伸縮(pc)
//-------------------------------------------------------------
function headerFixed () {
  var $window = $(window),
      $header = $('.header');

  var scrollTop = 0;

  //スクロール量がメインビジュアルの高さをこえたら、ヘッダーのスタイルを変更
  $window.on('scroll', function(){
    scrollTop = $(this).scrollTop();

    if(scrollTop > 0){
      $header.addClass('fixed change-color');
    } else {
      $header.removeClass('fixed change-color');
    }
  });

  //ヘッダーにマウスオーバーしたら、ヘッダーのスタイルを変更
  $header.on('mouseenter', function(){
    if(!$(this).hasClass('fixed')){
      $(this).addClass('change-color');
    }
  })
  .on('mouseleave', function(){
    if(!$(this).hasClass('fixed')){
      $(this).removeClass('change-color');
    }
  });

  if(mq('sp')){
    $window.on('scroll', function(){
      scrollTop = $(this).scrollTop();

      if(scrollTop > 0){
        $header.addClass('fixed');
      } else {
        $header.removeClass('fixed');
      }
    });
  }

  $window.trigger('scroll');
}

//ヘッダーの背景色、固定、伸縮(sp)
//-------------------------------------------------------------
function headerFixedSp () {
  var $window = $(window),
      $header = $('.header');

  var scrollTop = 0,
      bodyHeight = $('body').outerHeight() - $('.footer').outerHeight(); //スクロール量がフッター領域に入るスクロール量

  //スクロール量がメインビジュアルの高さをこえたら、ヘッダーのスタイルを変更
  $window.on('scroll', function(){
    var scrollAfter = $(this).scrollTop();

    var scrollDown = scrollTop < scrollAfter,  //下スクロール
        scrollUp = scrollTop > scrollAfter;    //上スクロール

    if(scrollAfter > 100 && scrollDown){

      //スクロール量が100を越え、かつス下スクロールした場合
      $header.addClass('fixed');

    } else if(scrollAfter > bodyHeight && scrollDown){

      //スクロール量がフッター領域に入り、かつ下スクロールした場合
      $header.addClass('fixed');

    } else if(scrollTop > bodyHeight){

      //スクロール量がフッター領域に入り、上スクロールした場合
      $header.addClass('fixed');

    } else {

      //フッター領域に入らない範囲内で、かつ上スクロールした場合
      $header.removeClass('fixed');

    }

    scrollTop = scrollAfter;
  });

  $window.trigger('scroll');
}

//メガメニューの表示
//-------------------------------------------------------------
function megaMenu () {
  var $header = $('.header'),
      $headerL = $('.header-l'),
      $trigger = $('.mega-trigger'),
      $megaMenu = $('.mega-menu');
  var headerHeight = 0;


  //グローバルナビにマウスオーバーした時の挙動
  $trigger.on('mouseenter', '> a', function(e){
    headerHeight = $header.outerHeight() + $('.mega-menu').outerHeight();
    headerLHeight = $headerL.outerHeight();

    if(!$(this).hasClass('fixed')){
      $header.addClass('change-color');
    }

    $header.addClass('mega-show');
    $(this).next('.mega-menu').css({top: headerLHeight});
    $(this).next('.mega-menu').addClass('show');

    console.log(headerHeight);
  })
  .on('mouseleave', '> a', function(){
    if($(this).hasClass('fixed')){
      $header.removeClass('change-color');
    }

    $header.removeClass('mega-show');
    $(this).next('.mega-menu').removeClass('show');
  });

  //メガメニューにマウスオーバーした時の挙動
  $megaMenu.on('mouseenter', function(e){
    $header.addClass('mega-show change-color');
    $(this).next('.mega-menu').css({top: headerLHeight});
    $(this).parent().find('> a').addClass('current');
  })
  .on('mouseleave', function(){
    $header.removeClass('mega-show');
    $header.removeClass('mega-show');
    $(this).parent().find('> a').removeClass('current');
  });
}


//メインビジュアルスライダー
//-------------------------------------------------------------
function mvSlide () {
  var mySwiper = new Swiper ('.swiper-container', {
    loop: true, // 最後のスライドまで到達した場合、最初に戻らずに続けてスライド可能にするか。
    speed: 1500, // スライドが切り替わるトランジション時間(ミリ秒)。
    slidesPerView: 1, // 何枚のスライドを表示するか
    spaceBetween: 0, // スライド間の余白サイズ(ピクセル)
    direction: 'horizontal', // スライド方向。 'horizontal'(水平) か 'vertical'(垂直)。effectオプションが 'slide' 以外は無効。
    effect: 'fade', // "slide", "fade"(フェード), "cube"(キューブ回転), "coverflow"(カバーフロー) または "flip"(平面回転)

    // スライダーの自動再生
    // autoplay: true 　のみなら既定値での自動再生
    autoplay: {
      delay: 5000, // スライドが切り替わるまでの表示時間(ミリ秒)
      stopOnLast: false, // 最後のスライドまで表示されたら自動再生を中止するか
     //  disableOnInteraction: true ユーザーのスワイプ操作を検出したら自動再生を中止するか
    }
  });
}


//電話機能をSP時のみ適用
//-------------------------------------------------------------
function callActive () {
  var $callNmuber = $('.call-number').find('a');
  var $callNmuberNpo = $('.call-number-npo').find('a');

  $callNmuber.on('click', function(e){
    if(mq('pc')){
      return false;
    }
  });
  $callNmuberNpo.on('click', function(e){
    if(mq('pc')){
      return false;
    }
  });
}


//バーガーメニュー
//-------------------------------------------------------------
function hbg() {
  var state = false;
  var scrollpos;
  var header = $('.header-l');
  var overlay = $('.ov');
  var trigger = $('.menu-trigger');
  var btnClose = $('.btn-close');

  trigger.on('click', function(){
    if($(this).hasClass('active')) {
      $(this).removeClass('active');
      $(this).find('.txt').text('menu');
      $(header).removeClass('active');
      $(overlay).fadeOut();
      state = true;
    } else {
      scrollpos = $(window).scrollTop();
      $(this).addClass('active');
      $(this).find('.txt').text('close');
      $(header).addClass('active');
      $(overlay).fadeIn();
      state = false;
    }
    return false;
  });

  btnClose.on('click', function(){
    if(trigger.hasClass('active')) {
      trigger.removeClass('active');
      trigger.find('.txt').text('menu');
      $(header).removeClass('active');
      $(overlay).fadeOut();
      state = true;
    } else {
      scrollpos = $(window).scrollTop();
      trigger.addClass('active');
      trigger.find('.txt').text('close');
      $(header).addClass('active');
      $(overlay).fadeIn();
      state = false;
    }
    return false;
  });
}


//下層ページサイドバーの追従
//-------------------------------------------------------------
function sideBar($sideBar) {
  var $window = $(window);

  var scrollTop = 0,
      triggerPos = $('.header').outerHeight() + $('.Mv-under').outerHeight(), //追従を始めるスクロール量
      stopPos = $('body').outerHeight() - $('.footer').outerHeight() - $('.sidebar').outerHeight() - 60, //追従をやめるスクロール量
      diff = 277; //高さの調整

  $window.on('scroll', function(){
    scrollTop = $window.scrollTop();

    if(scrollTop > triggerPos){

      //スクロール量が追従を始めるところまで来た時のサイドバーのトップからの位置
      $sideBar.stop().animate({top: scrollTop - diff}, 500);

      //スクロール量が追従をやめるところまで来たら、topの値を固定
      if(scrollTop >= stopPos){
        $sideBar.stop().animate({top: stopPos - diff}, 500);
      }

    } else {
      $sideBar.stop().animate({top: 0}, 500);
    }
  });

  $window.trigger('scroll');
}

//可視範囲に入ったらタクシーアニメーション
//-------------------------------------------------------------
$(function(){
  $('.effect').css("opacity","0");
  $(window).scroll(function (){
    $(".effect").each(function(){
      var imgPos = $(this).offset().top;
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll > imgPos - windowHeight + windowHeight/4){
        $(this).css("opacity","1" );
        $(".effect-thumb",this).addClass('active')
      }
      if (scroll > imgPos - windowHeight + windowHeight/5){
        $(this).css("opacity","1" );
        $(".effect-thumb02",this).addClass('active')
      }
    });
  });
});


//YESNOチャート
//-------------------------------------------------------------
$(function(){
  $(".answer-btn").on("click", function(e) {
     e.preventDefault();
     $(this).closest("div").css("display","none");
     var id = $(this).attr("href");
     $(".wh-box").removeClass("positionFit");
     $(id).addClass("positionFit").fadeIn();
  });
});


//グローバルメニューactive
//-------------------------------------------------------------
$('ul.career-menu li a').each(function(){
  if($($(this))[0].href==String(window.location)) {
    $(this).parent().addClass('active');
  }
});


//二階層等にも適用
$('ul.career-menu li a').each(function(){
  if($($(this))[0].href==String(window.location)) {
    $(this).parent().addClass('active');
    $(this).parent().parent().show();
  }
});

//scroll を間引き
function ScrollTrigger(){
  var target = $(".fade-out");
    var flug = true;

  $(window).on('load scroll',function(){
    if(flug){
    flug = false;
      setTimeout(function(){
        target.each(function(i){

        var docViewTop    = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();
        var elemTop       = $(this).offset().top;
        var elemBottom    = elemTop + $(this).height();

        if((elemTop <= docViewBottom) && (elemTop - 100 >= docViewTop) == true ||
           (elemBottom <= docViewBottom) && (elemTop >= docViewTop) == true
          ){
        $(this).addClass("fade-in").removeClass("fade-out");
          }
        });

      flug = true;
      return flug;
      }, 200);
    }
  })
}

$(function () {

  ScrollTrigger();
});

function pagetopAppear () {
  $('.pagetop').addClass('show');
}

//数字でわかる湘南交通見出しの高さ揃え
//-------------------------------------------------------------
jQuery(function($){
  var Infoboxtitle$ = $('.Infoboxtitle');
  var Infoboxtitle_length = Infoboxtitle$.length;
  
  for(var i = 0 ; i < Math.ceil(Infoboxtitle_length / 2) ; i++) {
    var maxHeight = 0;
    for(var j = 0; j < 2; j++){
      if (Infoboxtitle$.eq(i * 2 + j).height() > maxHeight) { 
        maxHeight = Infoboxtitle$.eq(i * 2 + j).height(); 
      }
    }
    for(var k = 0; k < 2; k++){
      Infoboxtitle$.eq(i * 2 + k).height(maxHeight); 
    }
  }      
});
// ============================================================
// ATTENTION & COMMON RULE!!
// まとめて関数実行（※必要に応じて条件分岐を入れる）
// ページ個別に処理をする場合は「ページ固有のID名.lengthで処理を分岐」
// ============================================================

// --- ページの全データを読み込み後 ----------------------------------
$(window).on('load', function() {
  if(mq('pc')){
    pagetopAppear();
  }
});

// --- リサイズが走った場合 ----------------------------------------
$(window).on('resize', function(){

});

// --- DOM生成後 -----------------------------------------------
$(function(){

  checkDeviceAndWidthFunc();
  hbg();
  goToPageTopFunc('.page-top');
  callActive();
  smoothScrollOtherPage();
  smoothScrollMoveFunc('.scroll');


  //トップページのみ適用
  if($('.toppage').length > 0){
    mvSlide();
  }

  // PC時のみ適用
  if(mq('pc')){
    megaMenu();
    headerFixed();
    sideBar($('.sidebar'));
  }

  // SP時のみ適用
  if (mq('sp')) {
    headerFixedSp();
    // if($('.toppage').length > 0){
    //   headerFixedSp();
    // }
    // if($('.detail').length > 0){
    //   headerFixedSp();
    // }
    // if($('.kosodatetaxi').length > 0){
    //   headerFixedSp();
    // }
    // if($('.taxiwiki').length > 0){
    //   headerFixedSp();
    // }
    // if($('.first-story').length > 0){
    //   headerFixedSp();
    // }
    // if($('.point').length > 0){
    //   headerFixedSp();
    // }
    // if($('.privacy').length > 0){
    //   headerFixedSp();
    // }
    // if($('.company').length > 0){
    //   headerFixedSp();
    // }

  }

});
